import {
    getweixininfo,
    getAdvertList,
    getShopAuth,
    updateToVip
} from '@/utils/user.js';
import useClipboard from "vue-clipboard3"
import receiveCoupon from '@/components/receiveCoupon.vue';
import servicePopup from '@/components/service.vue';
import phonePopup from '@/components/phone.vue';
import setPopup from '@/components/set.vue';
import { provide, ref } from 'vue';
export default {
    name: "User",
    setup() {
        let showGroup = ref(false);
        let showPhone = ref(false);
        let showSet = ref(false);
        provide('showgroup', showGroup);
        provide('showphone', showPhone);
        provide('showset', showSet);
        return {
            showGroup,
            showPhone,
            showSet
        }
    },
    components: {
        receiveCoupon,
        servicePopup,
        phonePopup,
        setPopup
    },
    data() {
        return {
            status: "",
            defaultAvator: require("../../../assets/img/user/home/default.png"),
            isShow: false,
            backgroundWise: {
                backgroundImage: "url(" + require("../../../assets/img/user/home/wise.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            userInfo: {},
            bannerList: [],
            refuseReason: "",
            isShowTj: false,
            inviteCode: ''
        }
    },
    created() {
        // 跳转登录页面
        // if (!this.$cookies.get('isLogin')) {
        //     this.$router.push({
        //         path: '/login'
        //     })
        // } else {
            this.initData()
            this.getBanner()
            this.shopStatus()
        // }
    },
    methods: {
        shopStatus() {
            let data = {
                // userId: this.$cookies.get('userId')
                memberId: this.$cookies.get('memberId')
            };
            getShopAuth(data).then(res => {
                if (res) {
                    if (res.code == "0") {
                        this.status = res.data.status
                        this.refuseReason = res.data.refuseReason
                    } else if (res.code == "-1") {
                        console.log(res)
                    } else {
                        this.$toast.fail(res.message)
                    }
                }
            })
        },
        toFun(item) {
            if (item.clickUrl) {
                window.location.href = item.clickUrl
            }
        },
        getBanner() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                advertType: 3
            };
            getAdvertList(data).then(res => {
                if (res && res.code == "0") {
                    this.bannerList = res.data.advertList || []
                }
            })
        },
        initData() {
            let data = {
                memberId: this.$cookies.get('memberId')
            };
            getweixininfo(data).then(res => {
                if (res && res.code == "0") {
                    this.userInfo = res.data || {}
                }
                this.userInfo.avator = this.userInfo.avator || this.defaultAvator
            })
        },
        async copyFun() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(this.userInfo.invitationCode)
                this.$toast.success("已复制邀请码")
            } catch (e) {
                console.error(e)
            }
        },
        phoneFun() {
            this.showPhone = true
        },
        immediateFun() {
            this.isShow = true
        },
        receiveFun() {
            this.isShow = false
        },
        hrefFun(path) {
            if (path == '/inviteFriend' || path == '/myFans') {
                if (this.userInfo.memberType == 0 || this.userInfo.memberType == 1 || this.userInfo.memberType == 2) {
                    this.$toast('非达人不可查看哦！');
                    return false;
                } else {
                    this.$router.push(path)
                }
            } else {
                this.$router.push(path)
            }
        },
        setFun() {
            this.showSet = true;
        },
        groupFun() {
            this.showGroup = true;
        },
        // 代付款
        waitPayFunc() {
            this.$router.push({
                path: '/orderList',
                query: {
                    orderStatus: '1'
                }
            })
        },
        // 关闭成为vip
        closeInviteFunc() {
            this.isShowTj = false;
            this.inviteCode = '';
        },
        // 添加成为vip
        addInviteFunc() {
            if (this.inviteCode == '') {
                this.$toast('输入您推荐人的邀请码');
                return false;
            }
            updateToVip({
                memberId: this.$cookies.get('memberId'),
                code: this.inviteCode
            }).then(res => {
                console.log('推荐吗', res)
                this.$toast.success('已成功关联！');
            })
        },
        // 推广员登录
        loginFunc() {
            this.$router.push({
                path: '/login'
            })
        }
    }
}