<template>
  <div class="user-box">
    <div class="user-box-main">
      <div :class="['box-name flex', {'box-fail': status == 2 }]">
        <img :src="userInfo.avator" :style="{ opacity: userInfo.avator ? 1 : 0 }" class="box-name-left" />
        <div class="box-name-center">
          <div class="grade flex">
            <p class="portrait">{{ userInfo.member_name || "" }}</p>
            <div class="vip flex align-center">
              <img class="vip-img" v-if="userInfo.memberType == 3" src="@/assets/img/user/home/crown1.png" alt="" />
              <img class="vip-img" v-if="userInfo.memberType == 4" src="@/assets/img/user/home/crown2.png" alt="" />
              <img class="vip-img" v-if="userInfo.memberType == 2" src="@/assets/img/user/home/crown3.png" alt="" />
              <!-- <p>{{ userInfo.member_level }}</p> -->
              <!-- <img src="@/assets/img/user/home/vip-right.png" alt="" /> -->
            </div>
          </div>
          <div class="invite-box">
            <div class="invite flex align-center">
              <!-- <p v-if="userInfo.memberType == 3 || userInfo.memberType == 4" @click="copyFun" class="text">邀请码：{{ userInfo.invitationCode }}</p> -->
              <img v-if="userInfo.memberType == 3 || userInfo.memberType == 4" @click="copyFun" src="@/assets/img/user/home/copy.png"/>
              <!-- <p v-if="status === 0" class="book-audit">门店审核中</p> -->
              <!-- <p v-else-if="status == 2" class="book-audit book-grey">门店审核失败</p> -->
              <div class="flex flex-center align-center vipbtn" v-if="userInfo.memberType == 0 || userInfo.memberType == 1" @click="isShowTj = true;">成为VIP</div>
            </div>
            <!-- <div v-if="status == 2" class="tip-box flex flex-right">
              <div class="book-tip flex">
                <img src="@/assets/img/user/home/three.png" class="three">
                <p class="reason">原因：{{ refuseReason }}</p>
                <p class="submit" @click="hrefFun('/storeAuth')">重新提交</p>
              </div>
            </div> -->
          </div>
        </div>
        <div class="box-name-right flex flex-right">
          <img src="@/assets/img/user/home/service.png" alt="" @click="phoneFun" />
          <img src="@/assets/img/user/home/set.png" alt="" @click="setFun" />
        </div>
      </div>
      <!-- <div v-if="userInfo.memberType === 0" class="box-wise-man flex" :style="backgroundWise">
        <div class="text">
          <p>成为种草达人</p>
          <p>可以获得推广收益</p>
        </div>
        <p class="immediate" @click="hrefFun('/memberInterest')">立即成为达人</p>
      </div> -->
      <div class="box-data">
        <div class="data-top flex flex-between">
          <p>累计数据</p>
          <img src="@/assets/img/user/home/right.png" @click="hrefFun('/profitRecord')"/>
        </div>
        <div class="three-data flex"  @click="hrefFun('/profitRecord')">
          <div class="num first">
            <p class="title">¥{{ (Number(userInfo.allmoney || 0) / 100).toFixed(2) }}</p>
            <div class="title-bottom flex flex-center align-center">
              <p>累计收益</p>
              <img src="@/assets/img/user/home/help.png" alt="" />
            </div>
          </div>
          <div class="num second">
            <p class="title">{{ Number(userInfo.allBonus || 0) }}</p>
            <div class="title-bottom flex flex-center align-center">
              <p>累计预估积分</p>
              <img src="@/assets/img/user/home/help.png" alt="" />
            </div>
          </div>
          <div class="num third">
            <p class="title">¥{{ (Number(userInfo.discountAmount || 0) / 100).toFixed(2) }}</p>
            <div class="title-bottom flex flex-center align-center">
              <p>已享受平台优惠</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-fun flex">
        <van-badge :content="userInfo.waitingForPay || ''" max="99" color="#F01520">
          <div class="fun first flex align-center fun-one" @click="waitPayFunc">
            <img src="@/assets/img/user/home/payment.png" alt="" />
            <p>待付款</p>
          </div>
        </van-badge>
        <div class="fun second flex align-center" @click="hrefFun('/myCollect')">
          <img src="@/assets/img/user/home/comment.png" alt="" />
          <p>我的收藏</p>
        </div>
        <div class="fun first flex align-center" @click="hrefFun('/couponList')">
          <img src="@/assets/img/user/home/coupon.png" alt="" />
          <p>优惠券</p>
        </div>
        <div class="fun second flex align-center" @click="hrefFun('/myFans')">
          <img src="@/assets/img/user/home/fans.png" alt="" />
          <p>我的粉丝</p>
        </div>
        <div class="fun five flex align-center" @click="hrefFun('/inviteFriend')">
          <img src="@/assets/img/user/home/add-people.png" alt="" />
          <p>邀请好友</p>
        </div>
      </div>
      <div class="box-brand" v-if="bannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(tab, tIndex) in bannerList" :key="tIndex">
            <img :src="tab.photoUrl" alt="" @click="toFun(tab)"/>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="box-tool">
        <div class="data-top flex flex-between">
          <p>常用工具</p>
        </div>
        <div class="tool-ul flex flex-between">
          <div class="tool-li flex align-center" @click="hrefFun('/teamReport')">
            <img src="@/assets/img/user/home/chart.png" alt="" />
            <p>团队报表</p>
          </div>
          <div class="tool-li flex align-center" @click="hrefFun('/platformRules')">
            <img src="@/assets/img/user/home/rule.png" alt="" />
            <p>平台规则</p>
          </div>
          <div class="tool-li flex align-center flex-center" @click="hrefFun('/addressList')">
            <img src="@/assets/img/user/home/addr.png" alt="" />
            <p>收货地址</p>
          </div>
          <div class="tool-li flex align-center" @click="hrefFun('/platformExplain')">
            <img src="@/assets/img/user/home/standard.png" />
            <p>推广规范</p>
          </div>
          <div class="hide tool-li flex align-center" @click="hrefFun('/platformExplain')">
            <img src="@/assets/img/user/home/course.png"/>
            <p>新手教程</p>
          </div>
        </div>
      </div>
      <div class="box-tool box-train">
        <div class="data-top flex flex-between">
          <p>赚钱培训</p>
        </div>
        <div class="tool-ul flex flex-between">
          <div class="tool-li flex align-center" @click="groupFun">
            <img src="@/assets/img/user/home/group.png" alt="" />
            <p>马上进群</p>
          </div>
          <div class="tool-li flex align-center" @click="hrefFun('/myNotice')">
            <img src="@/assets/img/user/home/notice.png" alt="" />
            <p>灵龙公告</p>
          </div>
          <div class="hide tool-li flex align-center">
            <img src="@/assets/img/user/home/notice.png" alt="" />
            <p>灵龙公告</p>
          </div>
          <div class="hide tool-li flex align-center">
            <img src="@/assets/img/user/home/notice.png" alt="" />
            <p>灵龙公告</p>
          </div>
          <div class="hide tool-li flex align-center">
            <img src="@/assets/img/user/home/notice.png" alt="" />
            <p>灵龙公告</p>
          </div>
        </div>
      </div>
      <!-- 推广员登录 -->
      <div class="flex flex-center align-center tg-login" @click="loginFunc">推广员登录</div>
      <div class="group-fixed">
        <img
          src="@/assets/img/user/home/add-group.png"
          alt=""
          @click="groupFun"
        />
      </div>
    </div>
    <!-- 弹窗 -->
    <receive-coupon></receive-coupon>
    <!-- 客服1 -->
    <phone-popup></phone-popup>
    <!-- 客服2 -->
    <service-popup></service-popup>
    <!-- 设置 -->
    <set-popup></set-popup>

    <!-- 添加推荐人 -->
    <van-popup
      v-model:show="isShowTj"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="alert-popup"
    >
      <div class="alert-box">
        <img
          src="@/assets/img/user/myFans/close.png"
          class="close"
          @click="closeInviteFunc"
        />
        <div class="code flex align-center flex-center">
          <p></p>
          成为VIP会员
          <p></p>
        </div>
        <div class="text">
          <p class="text-title">推荐人的邀请码：</p>
          <div class="text-div">
            <input type="text" v-model="inviteCode" placeholder="输入您推荐人的邀请码">
          </div>
        </div>
        <p class="btn-b" @click="addInviteFunc()">确认</p>
      </div>
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.user-box {
  .box-fun {
    .van-badge {
      top: 15px;
      left: 37px;
      right: unset !important;
      transform: unset !important;
      line-height: unset !important;
    }
  }
  .box-brand {
    .van-swipe__indicators {
      .van-swipe__indicator {
        width: 5px;
        height: 5px;
        background: #ffffff;
        opacity: 0.5;
        border-radius: unset !important;
      }
      .van-swipe__indicator--active {
        width: 16px;
        opacity: 1;
      }
      .van-swipe__indicator:not(:last-child) {
        margin-right: 5px !important;
      }
    }
  }
  .alert-popup {
        width: 100% !important;
        transform: unset !important;
        background: none !important;
        left: 0;
        top: 0 !important;
        background: unset !important;
        .alert-box {
          width: 280px;
          background: #ffffff;
          -webkit-border-radius: 8px;
          border-radius: 8px;
          position: relative;
          margin: 181px auto 0;
          padding: 20px 0;
          .btn-b {
            width: 230px;
            height: 36px;
            background: #e62129;
            border-radius: 18px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            line-height: 36px;
            border: 1px solid #e62129;
            margin: 0 auto;
          }
          .close {
            width: 24px;
            height: 24px;
            position: absolute;
            top: -34px;
            right: 1px;
          }
          .code {
            height: 21px;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 21px;
            text-align: center;
            margin-bottom: 25px;
            p {
              background: #e62129;
              width: 8px;
              height: 1.5px;
            }
            p:nth-child(1) {
              margin-right: 5px;
            }
            p:nth-child(2) {
              margin-left: 5px;
            }
          }
          .text {
            margin-bottom: 30px;
            &-title{
              width: auto;
              height: auto;
              color: #666666;
              font-size: 12px;
              font-weight: 400;
              padding: 0px 25px;
            }
            &-div{
              width: 230px;
              height: 40px;
              margin: 0 auto;
              margin-top: 8px;
              border-radius: 5px;
              background-color: #F6F6F6;
              overflow: hidden;
              input{
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                text-indent: 12px;
                color: #333;
                font-size: 15px;
                font-weight: 400;
                border: none;
                background: transparent;
              }
            }
            .left {
              width: 90px;
              height: 21px;
              font-size: 15px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 21px;
              text-align: left;
              margin-left: 40px;
              flex-shrink: 0;
            }
            .right {
              font-size: 15px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 21px;
              margin-right: 40px;
            }
          }
          .last-text {
            margin-bottom: 30px;
          }
        }
      }
}
</style>